import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  padding: 42px 0;
  flex-direction: column;
  background-color: ${grayscale[100]};

  @media ${device.desktopLG} {
    padding: 84px 0;
  }

  @media ${device.desktopXL} {
    padding: 132px 0 100px 0;
  }

  .button-width {
    max-width: 100%;
  }

  .carousel-div {
    min-height: 130px;

    @media${device.tablet} {
      min-height: 250px;
    }
  }

  hr {
    width: 95%;
  }

  .rounded-row {
    border-radius: 40px;
  }

  .custom-dots {
    border-radius: 50%;
    height: 25px;
    width: 25px;
    border: 1px solid ${grayscale[500]};
    padding-top: 2px;
    cursor: pointer;
  }

  .react-multi-carousel-dot-list {
    top: 0px;
  }

  .react-multi-carousel-track  {
    padding: 55px 0 50px;
  }

  button, a {
    border-radius: 8px !important;
  } 
`
