import React from 'react'
import Link from 'src/components/GatsbyLinkWrapper/index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import * as URLs from '../../config/api/Urls'

import './Breadcrumb.scss'

type BreadCrumbProps = {
  sectionName: string;
  text: string;
  link: string;
  type: string | React.ReactNode;
  product?: string;
  styles?: string;
}

function Breadcrumb ({ sectionName, text, link, type, product, styles }: BreadCrumbProps) {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <div className={`bread-crumb ${styles}`}>
      <span className='text-white fs-12'>
        {type} ▸ {product} {product ? ' ▸ ' : '' }
        <Link
          to={link}
          onClick={() => {
            sendDatalayerEvent({
              section: 'dobra_1',
              section_name: sectionName,
              element_action: 'click button',
              element_name: text,
              redirect_url: URLs.SITE_BASE_URL + link,
            })
          }}
        >{text}
        </Link>
      </span>
    </div>
  )
}

Breadcrumb.defaultProps = {
  text: '',
  link: '/',
  type: '',
  product: '',
  styles: '',
}

export default Breadcrumb
