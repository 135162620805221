import React, { useEffect, useState } from 'react'

type YoutubeVideoProps = {
  src: string;
  className?: string;
  width?: string;
  height?: string;
}

const YoutubeVideo = ({ src, className, width, height }: YoutubeVideoProps) => {
  const [ domReady, setDomReady ] = useState(false)

  useEffect(() => {
    setDomReady(true)
  }, [])

  return domReady
    ? (
      <iframe
        src={src.includes('/embed') ? src.replace('youtube.com', 'youtube-nocookie.com') : src.replace('youtube.com', 'youtube-nocookie.com/embed')}
        className={`border-0 optanon-category-C0003 ${className}`}
        allow='autoplay'
        width={width}
        height={height}
        allowFullScreen
      />
    )
    : <></>
}

export default YoutubeVideo
