import React from 'react'
import FAQ from 'src/components/Faq'
import pageContext from '../../pageContext.json'
import { FaqSection } from './style'

export const Faq = () => {
  return (
    <FaqSection id='perguntas-frequentes' className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 mb-4'>
            <h3 className='fs-24 lh-28 fs-md-40 lh-md-46 text-grayscale--400'>Perguntas frequentes</h3>
          </div>
          <FAQ id='seguro-saude' columns={{ sm: 1, md: 1, lg: 1, xl: 1 }} className='px-0' answerData={pageContext.structuredData.faq} />
        </div>
      </div>
    </FaqSection>
  )
}
