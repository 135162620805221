import styled, { keyframes, css } from 'styled-components'
import { white } from 'src/styles/colors'
import { breakpoints } from 'src/styles/breakpoints'

type ContainerProps = {
  isModal: boolean;
  isMinPadding: boolean;
}

const animatedModal = keyframes`
  from {
    right: -100%;
  }
  to {
    right: 0;
  }
`
export const Container = styled.div<ContainerProps>`
  top: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  transition: 0.5s ease-in-out;
  animation: ${animatedModal} 0.5s ease-in-out forwards;
  background-color: ${white};
  overflow-y: auto;
  display: block;
  height: 100%;
  
  ${(props: ContainerProps) => props.isModal && (
    css`
      min-height: 100vh;
      padding: 30px 24px;
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: ${breakpoints.md}) {
        max-width: 596px;
        padding: 40px 100px;
      }
    `
  )}

  ${(props: ContainerProps) => props.isModal && props.isMinPadding && (
    css`
      @media (min-width: ${breakpoints.md}) {
        max-width: 512px;
        padding-left: 40px;
        padding-right: 40px;
      }
      @media (min-width: ${breakpoints.xl}) {
        max-width: 596px;
        padding-left: 72px;
        padding-right: 72px;
      }
    `
  )},

  .open-modal {
    width: 100%;
  }

  .center-content{
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  background: none;
  border: none;
  top:30px;
  cursor: pointer;
  right: 30px;

  &:focus{
    outline: none;
  }
`
export const Image = styled.img`
  max-width: 187px;
  max-height: 187px;
`
