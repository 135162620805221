import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const SectionWrapper = styled.section`
  display: flex;
  align-items: center;
  padding: 42px 0;
  flex-direction: column;
  background-color: ${orange.extra};

  @media ${device.desktopLG} {
    padding: 84px 0;
  }

  @media ${device.desktopXL} {
    padding: 132px 0 100px 0;
  }

  .button-width {
    max-width: 100%;
  }

  .carousel-div {
    min-height: 130px;

    @media${device.tablet} {
      min-height: 250px;
    }
  }

  .react-multi-carousel-dot-list {
    .react-multi-carousel-dot {
      button { 
        background-color: #fff;
      }
    }
  }  

  .react-multiple-carousel__arrow--right {
    svg{
      fill: #fff;
    }
  }

  .react-multiple-carousel__arrow--left {
    svg{
      fill: #fff;
    }
  }

  button, a {
    border-radius: 8px !important;
  }
`

export const ButtonVideo = styled.button`
  width: 100%;
  position: relative;
  top: 8px;
  background: transparent;
  border: none;
`
