import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { orange, gray } from 'src/styles/colors'

export const SectionWrapper = styled.section`
  flex-direction: column;
  min-height: calc(100vh - 64px);

  @media ${device.tablet} {
    flex-direction: initial;
    min-height: 420px;
  }

  @media ${device.desktopLG} {
    min-height: 550px;
  }

  @media ${device.desktopXL} {
    min-height: calc(100vh - 73px);

    .gatsby-image-wrapper {
      max-width: 455px;
    }
  }

  .button-width {
    max-width: 100%;
  }

  .bread-crumb {
    span {
      color: ${gray['800']} !important;

      a {
        color: ${gray['800']};
        font-weight: 900;

        &:hover {
          color: ${orange.base} !important;
        }
      }
    }
  }
  
  button, a {
    border-radius: 8px !important;
  }
`

export const ButtonVideo = styled.button`
  width: 100%;
  position: relative;
  top: 8px;
  background: transparent;
  border: none;
`
