import React from 'react'
import OpenVideo from 'src/components/OpenVideo'
import Img from 'gatsby-image'
import { ButtonVideo, SectionWrapper } from './style'
import usePageQuery from '../../pageQuery'
import Breadcrumb from 'src/components/Breadcrumb'
import useWidth from 'src/hooks/window/useWidth'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setIsOpen: Function;
}

const PlanoCerto = ({ setIsOpen }: modalProps) => {
  const data = usePageQuery()
  const width = useWidth()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionWrapper className='d-flex position-relative justify-content-between align-items-center'>
      <div className='container py-4 pt-md-0 pb-md-5'>
        <div className='row justify-content-between align-items-center'>
          <div className='col-12 col-md-6 col-xl-5'>
            { width >= 768 &&
              <Breadcrumb
                sectionName='O plano de saúde certo para você e seu bolso'
                type='pra você ▸ Seguros'
                text='Plano de saúde'
                link='/pra-voce/seguros/plano-de-saude/'
              />
            }
            <h1 className='fs-24 fs-md-32 fs-lg-48 lh-30 lh-md-40 fw-600 lh-lg-56 text-grayscale--500 mb-4 pt-md-2'>
              <span className='d-md-block'>O plano de saúde </span>certo para você e <span className='d-md-block'>seu bolso</span>
            </h1>
            <p className={`fs-14 fs-md-16 fs-lg-18 lh-lg-22 lh-20 mb-4 ${width < 768 ? 'text-grayscale--400' : 'text-grayscale--500'}`}>
              No Inter você escolhe entre várias operadoras, todas com preços competitivos. E ainda contrata seu plano direto pelo app.
            </p>
            <div className='w-100'>
              {
                width >= 768
                ? (
                  <button
                    className=' btn--lg btn btn-orange--extra rounded-2 fs-14 fw-600 mt-2 mb-lg-0 text-none w-100 button-width'
                    onClick={ () => {
                      setIsOpen(true)
                      sendDatalayerEvent({
                        section: 'dobra_1',
                        section_name: 'O plano de saúde certo para você e seu bolso',
                        element_action: 'click button',
                        element_name: 'Simular no app',
                      })
                    }}
                  >
                    Simular no app
                  </button>
                )
                : (
                  <a
                    className='btn--lg btn btn-orange--extra rounded-2 fs-14 fw-600 mt-2 mb-lg-0 text-none w-100 button-width'
                    href='https://intergo.app/0edd3865'
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_1',
                        section_name: 'O plano de saúde certo para você e seu bolso',
                        element_action: 'click button',
                        element_name: 'Simular no app',
                        redirect_url: 'https://intergo.app/0edd3865',
                      })
                    }}
                  >
                    Simular no app
                  </a>
                )
              }
            </div>
          </div>
          {
            width >= 768
            ? (
              <div className='col-12 col-md-6 col-lg-4 pl-lg-0'>
                <OpenVideo
                  link='https://www.youtube.com/embed/EX2e3x2fk4k'
                >
                  <ButtonVideo
                    onClick={() => {
                      sendDatalayerEvent({
                        section: 'dobra_1',
                        section_name: 'O plano de saúde certo para você e seu bolso',
                        element_action: 'click video',
                        element_name: 'Assistir Vídeo',
                      })
                    }}
                  >
                    <Img fluid={data.seguroSaudeHeaderDesktopLG.fluid} alt='Assistir Vídeo' />
                  </ButtonVideo>
                </OpenVideo>
              </div>
            ) : ''
          }
        </div>
      </div>
      {
        width < 768
        ? (
          <div className='w-100'>
            <OpenVideo link='https://www.youtube.com/embed/EX2e3x2fk4k'>
              <ButtonVideo
                className='px-0'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_1',
                    section_name: 'O plano de saúde certo para você e seu bolso',
                    element_action: 'click video',
                    element_name: 'Assistir Vídeo',
                  })
                }}
              >
                <Img fluid={data.seguroSaudeHeaderMB.fluid} alt='Assistir Vídeo' />
              </ButtonVideo>
            </OpenVideo>
          </div>
        ) : ''
      }
    </SectionWrapper>
  )
}

export default PlanoCerto
