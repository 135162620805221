import React, { useState } from 'react'
import useDomReady from 'src/hooks/window/useDomReady'
import Layout from 'src/layouts/BaseLayout'
import { Modal } from 'src/components/Modal'
import { QrCodeModal } from 'src/components/Modal/QrCodeModal'

import PlanoCerto from './sections/plano-certo/_PlanoCerto'
import VariasOperadoras from './sections/varias-operadoras/_VariasOperadoras'
import { TudoPeloApp } from './sections/tudo-pelo-app/_TudoPeloApp'
import ComOsBeneficios from './sections/com-os-beneficios/_ComOsBeneficios'
import { Faq } from './sections/faq/_Faq'
import AnsFooter from './sections/ans-footer/_AnsFooter'

import QrCode from './assets/qrcode-seguro-saude.png'
import pageContext from './pageContext.json'
import { Wrapper } from './style'

function Seguros () {
  const domReady = useDomReady()
  const [ isOpen, setIsOpen ] = useState(false)

const AppSimulationModal = domReady && (
  <Modal isModalOpen={isOpen} setIsModalOpen={setIsOpen} locationToRender={document.body}>
    <QrCodeModal
      isModal={isOpen}
      setIsModal={setIsOpen}
      title='Agora <span class="text-orange--extra">abra nosso app</span><span class="d-block">e faça a sua simulação.</span>'
      instructions='Ligue a câmera do seu celular e aponte para o QR Code acima. Você será redirecionado para o app do Inter.'
      qrCode={QrCode}
    />
  </Modal>
)

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        {AppSimulationModal}
        <PlanoCerto setIsOpen={setIsOpen} />
        <VariasOperadoras setIsOpen={setIsOpen} />
        <TudoPeloApp setIsOpen={setIsOpen} />
        <ComOsBeneficios setIsOpen={setIsOpen} />
        <Faq />
        <AnsFooter />
      </Layout>
    </Wrapper>
  )
}

export default Seguros
