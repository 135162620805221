import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

// Background Images
import TudoPeloAppBackgroundMD from '../../assets/tudo-pelo-app-bg-md.png'
import TudoPeloAppBackgroundLG from '../../assets/tudo-pelo-app-bg-lg.png'
import TudoPeloAppBackgroundXL from '../../assets/tudo-pelo-app-bg-xl.png'

export const TudoPeloAppSection = styled.section`
  position: relative;

  .bg-link-md {
    width: 100%;
    height: 280px;
    position: absolute;
    top: 0;
  }

  .bg-link-lg {
    height: 330px;
  }

  @media ${device.tablet} {
    display: flex;
    align-items: flex-end;
    background-image: url(${TudoPeloAppBackgroundMD});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 668px;
  }

  @media(min-width: 880px) {
    min-height: 720px;
  }

  @media ${device.desktopLG} {
    display: flex;
    align-items: center;
    background-image: url(${TudoPeloAppBackgroundLG});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 525px;
  }

  .text-content {
    @media(min-width: 1200px) {
      padding-left: 20px !important;
    }
  }

  @media ${device.desktopXL} {
    display: flex;
    align-items: center;
    background-image: url(${TudoPeloAppBackgroundXL});
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 550px;
  }
  
  button {
    @media ${device.tablet} {
      max-width: 320px;
    }
    @media ${device.desktopLG} {
      max-width: 100%;
    }
    @media ${device.desktopXL} {
      max-width: 320px;
    }
  }

  button, a {
    border-radius: 8px !important;
  }
`
