import React from 'react'
import { TudoPeloAppSection } from './style'
import OpenVideo from 'src/components/OpenVideo'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

type modalProps = {
  setIsOpen: Function;
}

export const TudoPeloApp = ({ setIsOpen }: modalProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <TudoPeloAppSection className='py-5 bg-white'>
      <div
        onClick={() =>
          sendDatalayerEvent({
            section: 'dobra_3',
            section_name: 'É tudo pelo app, sem complicação',
            element_action: 'click video',
            element_name: 'Assistir ao Vídeo',
          })
      }
      >
        <OpenVideo link='https://www.youtube.com/embed/ye7LdKGkzD8'>
          <div className='bg-link-md d-none d-md-inline d-lg-none w-100' />
        </OpenVideo>
      </div>
      <div className='container'>
        <div className='row justify-content-lg-end'>
          <div
            className='d-none d-lg-inline col-lg-7'
            onClick={() =>
              sendDatalayerEvent({
                section: 'dobra_3',
                section_name: 'É tudo pelo app, sem complicação',
                element_action: 'click video',
                element_name: 'Assistir ao Vídeo',
              })
            }
          >
            <OpenVideo link='https://www.youtube.com/embed/ye7LdKGkzD8'>
              <div className='bg-link-lg w-100' />
            </OpenVideo>
          </div>
          <div className='text-content col-md-8 pl-0 col-lg-5'>
            <h2 className='fs-24 lh-28 fs-md-40 lh-md-45 fw-600 text-grayscale--500 mb-4 mb-md-0'>
              É tudo pelo app,{' '}
              <span className='d-lg-block d-xl-inline'>sem</span> complicação
            </h2>
            <div
              className='col d-md-none'
              onClick={() =>
                sendDatalayerEvent({
                  section: 'dobra_3',
                  section_name: 'É tudo pelo app, sem complicação',
                  element_action: 'click video',
                  element_name: 'Assistir ao Vídeo',
                })
              }
            >
              <OpenVideo link='https://www.youtube.com/embed/ye7LdKGkzD8'>
                <Img fluid={data.tudoPeloAppMB.fluid} alt='Assistir Vídeo' />
              </OpenVideo>
            </div>
            <div className='mt-4 mb-5'>
              <p className='fs-16 fs-md-18 lh-20 text-grayscale--400'>
                Você não precisa conversar com nenhum consultor. Os preços que
                você vê já são os melhores sempre e a contratação é 100%
                digital. Você tem autonomia pra fazer tudo pelo nosso app.
              </p>
            </div>
            <div className='col px-0'>
              <button
                className='d-none d-md-block btn--lg btn btn-orange--extra rounded-2 fs-14 fw-600 mt-2 mb-lg-0 text-none w-100'
                onClick={ () => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_3',
                    section_name: 'É tudo pelo app, sem complicação',
                    element_action: 'click button',
                    element_name: 'Simular no app',
                  })
                }}
              >
                Simular no app
              </button>
              <a
                className='d-block d-md-none btn--lg btn btn-orange--extra rounded-2 fs-14 pt-3 fw-600 mt-2 mb-lg-0 text-none w-100'
                href='https://intergo.app/0edd3865'
                onClick={ () => {
                  sendDatalayerEvent({
                    section: 'dobra_3',
                    section_name: 'É tudo pelo app, sem complicação',
                    element_action: 'click button',
                    element_name: 'Simular no app',
                    redirect_url: 'https://intergo.app/0edd3865',
                  })
                }}
              >
                Simular no app
              </a>
            </div>
          </div>
        </div>
      </div>
    </TudoPeloAppSection>
  )
}
