import React from 'react'
import Img from 'gatsby-image'
import { SectionWrapper } from './style'
import usePageQuery from '../../pageQuery'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import carouselData from './data.json'

type CarouselData = {
  image: string;
  alt: string;
}

type modalProps = {
  setIsOpen: Function;
}

const VariasOperadoras = ({ setIsOpen }: modalProps) => {
  const data = usePageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SectionWrapper>
      <div className='container'>
        <div className='row justify-content-between'>
          <div className='col-12 col-lg-5 col-xl-4 d-lg-flex flex-column justify-content-center'>
            <h2 className='fs-24 fs-md-32 fs-lg-40 lh-lg-50 lh-md-40 lh-30 mb-md-4 text-white'>Várias operadoras em um só lugar</h2>
            <p className='fs-16 fs-md-18 lh-md-22 lh-20 text-white fw-400 mb-lg-4'>Essas são algumas das operadoras que você encontra no nosso app.</p>
            <button
              className='d-none d-lg-block btn--lg btn btn-white text-orange--extra rounded-2 fs-14 fw-600 mt-2 mb-lg-0 text-none w-100 button-width'
              onClick={ () => {
                setIsOpen(true)
                sendDatalayerEvent({
                  section: 'dobra_2',
                  section_name: 'Várias operadoras em um só lugar',
                  element_action: 'click button',
                  element_name: 'Simular no app',
                })
              }}
            >
              Simular no app
            </button>
            <a
              className='d-block d-md-none btn--lg btn btn-white text-orange--extra rounded-2 fs-14 fw-600 mt-2 pt-3 mb-lg-0 text-none w-100 button-width'
              href='https://intergo.app/0edd3865'
              onClick={ () => {
                sendDatalayerEvent({
                  section: 'dobra_2',
                  section_name: 'Várias operadoras em um só lugar',
                  element_action: 'click button',
                  element_name: 'Simular no app',
                  redirect_url: 'https://intergo.app/0edd3865',
                })
              }}
            >
              Simular no app
            </a>
          </div>
          <div className='col-12 col-lg-6'>
            <DefaultCarousel
              sm={{ items: 1, partialVisibilityGutter: 80 }}
              md={{ items: 2, partialVisibilityGutter: 50 }}
              lg={{ items: 1, partialVisibilityGutter: 50 }}
              xl={{ items: 2, partialVisibilityGutter: 50 }}
            >
              {
                carouselData.map((item: CarouselData, index: number) => (
                  <div key={index} className='py-3 px-3 mr-4 bg-white rounded-3 d-flex justify-content-center align-items-center carousel-div'>
                    <Img fluid={data[item.image].fluid} alt={item.alt} />
                  </div>
                ))
              }
            </DefaultCarousel>
            <div className='d-flex justify-content-center w-100'>
              <button
                className='d-none d-md-block d-lg-none mt-5 btn--lg btn btn-white text-orange--extra rounded-2 fs-14 fw-600 mt-2 mb-lg-0 text-none'
                onClick={ () => {
                  setIsOpen(true)
                  sendDatalayerEvent({
                    section: 'dobra_2',
                    section_name: 'Várias operadoras em um só lugar',
                    element_action: 'click button',
                    element_name: 'Simular no app',
                  })
                }}
              >
                Simular no app
              </button>
            </div>

          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default VariasOperadoras
