import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

export const FaqSection = styled.section`
input[type=text] {
    background-color: #F5F6FA!important;
  } 

  .summary-details {

    .summary {
      background: transparent;
      border-radius: 0;
      border: none;
      border-bottom: solid 0.5px rgba(140, 145, 169, 0.2)!important;
      display: block;
      transition: 100ms all ease;
      width: 100%;

      .summary-content {
        align-items: center;
        padding-left: 0;
        p {
          color: ${grayscale[400]};
        }
        span {
          color: ${grayscale[400]};
          line-height: 19px;
        }
      }
    }
  }

  button {
    @media ${device.tablet} {
      max-width: 100%;
    }
  }
`