import React from 'react'
import Img from 'gatsby-image'
import usePageQuery from '../../pageQuery'
import { SectionWrapper } from './style'

const AnsFooter = () => {
  const data = usePageQuery()

  return (
    <SectionWrapper>
      <div className='container py-4'>
        <div className='row justify-content-md-center align-items-center'>
          <div className='col-12 col-md-3 col-lg-2 col-xl-2 mb-2 mb-md-0'>
            <Img fluid={data.seguroSaudeAns.fluid} />
          </div>
          <div className='col-5 col-md-3 col-lg-2 col-xl-1 py-3'>
            <Img fluid={data.sulamericaAns.fluid} />
          </div>
          <div className='col-5 col-md-3 col-lg-2 col-xl-1 py-3'>
            <Img fluid={data.bradescoAns.fluid} />
          </div>
          <div className='col-5 col-md-3 col-lg-2 col-xl-1 py-3'>
            <Img fluid={data.amilAns.fluid} />
          </div>
          <div className='col-5 col-md-3 col-lg-2 col-xl-1 py-3'>
            <Img fluid={data.unimedAnsFooter.fluid} />
          </div>
          <div className='col-5 col-md-3 col-lg-2 col-xl-1 py-3'>
            <Img fluid={data.qualicorpAns.fluid} />
          </div>
          <div className='col-12 col-md-8 col-xl-5 mt-2 mt-xl-0'>
            <p className='fs-10 text-grayscale--500 lh-12 mb-0 fw-600'>
              A Qualicorp mantém parcerias com a Central Nacional Unimed, Unimed Fortaleza, Unimed Belo Horizonte,
              Unimed Juiz de Fora, Unimed Natal, Unimed Nova Friburgo, Unimed Porto Alegre, Unimed Rio e Unimed Santos,
              integrantes do Sistema Nacional Unimed.
            </p>
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}

export default AnsFooter
